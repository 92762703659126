import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Outlet, useLocation } from 'react-router-dom';

import './style/index.scss';

import Home from './script/pages/Home';
import Team from './script/pages/Team';
import Solutions from './script/pages/Solutions';
import Contact from './script/pages/Contact';
import NoPage from './script/pages/NoPage';

import Header from './script/components/Header';
import Footer from './script/components/Footer';
import ConfirmSubmit from './script/pages/ConfirmSubmit';

function Layout(_) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <>
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="it-solutions" element={<Solutions />} />
                    <Route path="top-team" element={<Team />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="confirm" element={<ConfirmSubmit />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

import { useEffect } from "react";
import teamData from "../../resources/team-members.json";
import "../../style/pages/Team.scss";

export default function Team() {
    useEffect(() => {
        document.title = "Topassist - Top Team";
    }, []);

    return (
        <div className="team-page">
            <meta name="description" content="Topassist - Ons team" />
            <div className="team">
                {teamData.map((member) => (
                    <div className="member" key={member.name}>
                        <img src={member.imgUrl} width="1" height="1" alt="Employee" loading="lazy" />
                        <div className="details">
                            <h1>{member.name}</h1>
                            <h2>{member.title}</h2>
                            <p>{member.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

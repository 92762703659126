import { faCloud, faComment, faComputer, faHeadset, faLock, faNetworkWired, faPhone, faSimCard } from '@fortawesome/free-solid-svg-icons';

export const ItSolutions = [
    {
        title: "Helpdesk",
        icon: faHeadset,
        content:
            <div>
                Onze professionele bemande helpdesk is binnen kantooruren voor onze klanten
                telefonisch bereikbaar en we hebben een storingsdienst die buiten de kantooruren
                voor storingen kan worden gebeld.
            </div>
    },
    {
        title: "Werkplekbeheer",
        icon: faComputer,
        content:
            <div>
                <ul>
                    <li>Complete ontzorging, uw gemak staat centraal.</li>
                    <li>Voor zowel hard- als software.</li>
                    <li>Van bestelling tot installatie.</li>
                    <li>Inclusief updates en ondersteuning.</li>
                </ul>
            </div>
    },
    {
        title: "Back-up",
        icon: faCloud,
        content:
            <div>
                <ul>
                    <li>Gecentraliseerde databescherming lokaal en in de cloud.</li>
                    <li>Snel en betrouwbaar herstel van uw data.</li>
                </ul>
            </div>
    },
    {
        title: "Telefonie & VoIP",
        icon: faPhone,
        content:
            <div>
                <ul>
                    <li>Zakelijke telefonie, vast en mobiel.</li>
                    <li>Op maat en naar wens ingericht.</li>
                </ul>
            </div>
    },
    {
        title: "Advies",
        icon: faComment,
        content:
            <div>
                ICT Advies over uw bedrijf wordt in elk stadium desgewenst gegeven. ICT moet
                uiteindelijk een goed afgestemd en betrouwbaar geheel van processen en systemen
                vormen. Hiervoor is kennis en vakmanschap nodig waarover ons TOPteam beschikt. We maken
                graag een afspraak om ook u te adviseren over ICT binnen uw bedrijf.
                <br /><br />
                Samen met u bekijken we de mogelijkheden en wensen voor ICT binnen uw bedrijf.
                Deze wensen en mogelijkheden worden omgezet in een voorstel en/of ontwerp.
                Na een kennismakingsgesprek ontwerpen we voor u een veilige en betrouwbare ICT
                omgeving.
            </div>
    },
    {
        title: "Beveiliging",
        icon: faLock,
        content:
            <div>
                Antivirussoftware, encryptie, certificaten.
            </div>
    },
    {
        title: "Abonnementenbeheer",
        icon: faSimCard,
        content:
            <div>
                <ul>
                    <li>Nieuw, verlengd, aangepast of opgezegd.</li>
                    <li>Één mail of telefoontje en wij regelen de rest.</li>
                </ul>
            </div>
    },
    {
        title: "Netwerk en systeembeheer",
        icon: faNetworkWired,
        content:
            <div>
                <ul>
                    <li>Betrouwbaar en passend IT-beheer.</li>
                    <li>Voor een veilige en efficiente werkomgeving.</li>
                </ul>
            </div>
    },
]
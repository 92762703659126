import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItSolutions } from "../../resources/it-solutions";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "../../style/pages/Solutions.scss";

export default function Solutions() {
    const solutionRefs = useRef([]);
    const [searchParams] = useSearchParams();

    const [openSection, setOpenSection] = useState(-1);
    const [openSectionTimeout, setOpenSectionTimeout] = useState(null);

    function closeSection(index) {
        const ref = solutionRefs.current[index];
        requestAnimationFrame(() => {
            ref.querySelector(".content").style.height = `${ref.querySelector(".content>div").offsetHeight}px`;
            requestAnimationFrame(() => (ref.querySelector(".content").style.height = "0"));
        });
    }

    function toggleOpenSection(index) {
        if (openSectionTimeout !== null) clearTimeout(openSectionTimeout);

        if (openSection === index) {
            setOpenSection(-1);
            closeSection(index);
        } else {
            if (openSection > -1) closeSection(openSection);

            setOpenSection(index);

            const ref = solutionRefs.current[index];
            ref.querySelector(".content").style.height = `${ref.querySelector(".content>div").offsetHeight}px`;
            setOpenSectionTimeout(setTimeout(() => (ref.querySelector(".content").style.height = `fit-content`), 1100));
        }
    }

    useEffect(() => {
        document.title = "Topassist - IT Oplossingen";

        // Set opened solution from query parameter
        const queryIndex = parseInt(searchParams.get("opened"));
        if (queryIndex !== undefined && queryIndex > -1 && queryIndex < ItSolutions.length) {
            setOpenSection(queryIndex);

            const ref = solutionRefs.current[queryIndex];
            ref.querySelector(".content").style.height = `${ref.querySelector(".content>div").offsetHeight}px`;
            setOpenSectionTimeout(setTimeout(() => (ref.querySelector(".content").style.height = `fit-content`), 1100));
        }
    }, [searchParams]);

    return (
        <div className="solutions-page">
            <meta name="description" content="Topassist - Onze IT-oplossingen" />
            <div className="solutions">
                {ItSolutions.map((solution, i) => (
                    <div
                        ref={(el) => (solutionRefs.current[i] = el)}
                        key={`key-${i}`}
                        className={`solution ${i === openSection ? "open" : ""}`}
                    >
                        <div className="header" onClick={() => toggleOpenSection(i)}>
                            <span>
                                <FontAwesomeIcon icon={solution.icon} />
                                <h2>{solution.title}</h2>
                            </span>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </div>
                        <div className="content">
                            <div className="content-container">{solution.content}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

import { useEffect } from "react";
import "../../style/pages/Home.scss";
import NavIconRect from "../components/NavIconRect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItSolutions } from "../../resources/it-solutions";

export default function Home() {
    useEffect(() => {
        document.title = "Topassist - Home";
    }, []);

    return (
        <div className="panel-page home-page">
            <meta name="description" content="Topassist - Home" />
            <div className="eye-catcher-container">
                <video autoPlay playsInline loop muted>
                    <source src="/images/background.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="content">
                <div className="panel over-eye-catcher objective">
                    <h1>Onze <span className="accented">missie</span></h1>
                    <p>
                        Wij geloven dat onze klanten en alle bedrijven voordeel kunnen halen uit een digitale toekomst.
                        Wij helpen hierbij invulling te geven door gebruikersvriendelijke en flexibele oplossingen op
                        ICT gebied te bieden waarmee overal en altijd efficiënter en productiever gewerkt kan worden.
                    </p>
                    <h1>Onze <span className="accented">visie</span></h1>
                    <p>
                        Ondersteunen van bedrijven in het maken van de juiste keuzes op het gebied van ICT.
                        Bij het bedrijf van de klant passende ICT oplossingen implementeren en beheren. We zorgen voor
                        een veilige, snelle en stabiele werkomgeving. Een interactieve werkplek waarin prettig en
                        productief gewerkt kan worden.
                    </p>
                </div>
                <div className="panel it-solutions">
                    <h1>
                        <span className="accented">iT</span>-oplossingen
                    </h1>
                    <h3>
                        Een paar services die wij bieden vind je hieronder, druk op een knop om meer informatie te zien
                    </h3>
                    <div className="icons">
                        {ItSolutions.map((s, i) => (
                            <NavIconRect
                                to={`/it-solutions?opened=${i}`}
                                icon={<FontAwesomeIcon icon={s.icon} />}
                                text={s.title}
                                key={s.title}
                            />
                        ))}
                    </div>
                </div>
                {/* <div className="partners">
                    <h1>Partners</h1>
                    <div className="links">
                        <a href="https://ictwaarborg.nl/" target="_blank" rel="noreferrer">
                            <img src="https://img.nwalraven.me/topassist/logos/ict-waarborg_720" alt="ICT-waarborg logo"></img>
                        </a>
                        <a href="https://www.nldigital.nl/" target="_blank" rel="noreferrer">
                            <img src="https://img.nwalraven.me/topassist/logos/nl-digital" alt="NL-digital logo"></img>
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

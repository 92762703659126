import "../../style/pages/ConfirmSubmit.scss";

export default function ConfirmSubmit() {
    return (
        <div className="confirmation-page">
            <p class="success-message">Het contact formulier is met succes verstuurd!</p>
            <p>Gebruik de navigatie knoppen om terug te keren naar de website.</p>
        </div>
    );
}
